import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Util } from "./util";
import { Component, Input } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Location } from "@angular/common";

@Injectable()
export class HttpServer {
  constructor(
    private http: HttpClient,
    private util: Util,
    private loc: Location
  ) {}

  SendData(
    model: any,
    url: any,
    mode: string,
    isBannerNeed = true,
    isDirectUrl = false,
    token = null
  ) {
    if (isDirectUrl === true) {
      url = url;
    }

    if (this.util.isProd()) {
      url = environment.prodShoreAPI + "/" + url;
    } else if (this.util.isDev()) {
      url = environment.devShoreAPI + "/" + url;
    } else {
      //url = environment.devShoreAPI + "/" + url;
      url = environment.prodShoreAPI + "/" + url;
    }

    let headers: any;
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "",
    };

    if (!this.util.IsNotEmpty(token)) {
      token = this.util.getToken();
    }

    if (this.util.IsNotEmpty(token)) {
      headers.Authorization = "Bearer " + token;
    }

    if (!this.util.IsNotEmpty(headers.Authorization)) {
      delete headers.Authorization;
    }
    delete headers.Authorization;

    return fetch(url, {
      method: mode ? mode.toUpperCase() : "GET",
      headers,
      body: model ? JSON.stringify(model) : null,
    }).then((Response) => Response.clone().json());
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getJSON(jsonUrl: string): Observable<any> {
    return this.http.get(jsonUrl).pipe(catchError(this.handleError));
  }
}
